// @import "bootstrap-customized";
@import "fontawesome";
@import "tailwind";

[data-theme="dark"] .logoImg{
    filter: invert(1)
}

html {
  height: 100%;
  height: 100vh;
  height: 100dvh;

  body {
    display: flex;
    flex-direction: column;
    min-height: 100%;
    min-height: 100vh;
    min-height: 100dvh;
    margin: 0;
    padding: 0;
  }

  &.viewport-sized {
    overflow: hidden;

    body {
      overflow: hidden;
      height: 100%;
      height: 100vh;
      height: 100dvh;
    }
  }
}
